import React from 'react';
import './App.css';

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      info: null,
      wasFetchError: false
    }
  }

  componentDidMount() {
    const baseUrl = process.env.REACT_APP_API_BASE_URL || "http://localhost:5000";

    fetch(`${baseUrl}/api/helmet-info-url?tagUrl=${encodeURIComponent(window.location)}`)
      .then(response => response.json())
      .then(response => {
          this.setState({
            info: response
          });
        })
        .catch(reason => {
          this.setState({
            wasFetchError: true
          })
        });
  }

  render() { 
    if(this.state.info) {
      return (
        <div className="your-helmet">
            <img src="/Chip+Black.png" alt="helmet"></img>
            <div className="info">
              <h1>Your Helmet</h1>
              <span className="helmet-id">{this.state.info.helmetId}</span>
              <p>Helmet has been scanned {this.state.info.scanNumber} times</p>
            </div>
        </div>
      );
    }
    else if(this.state.wasFetchError) {
      return (<div className="app">Could not load info</div>)
    }
    else {
      return (<div className="app">Nothing to display</div>)
    }
  }

    
}

export default App;
